<template>
  <k-crud-table
    :headers='cameraColumns'
    :items='cruddataMX.dataList'
    :value="cruddataMX.pagination.current"
    :total='cruddataMX.pagination.total'
    @pageChanged='
      (page) => {
        crudPaginationMX().change(page);
      }
    '
  >
    <template #top>
      <k-crud-dialog
        v-model='lookOff'
        :actionshow='false'
        :title='rowItem.orgName'
        maxwidth='900px'
        @close='lookOff = false'
        @save='lookOff = false'
      >
        <template #dialogContent>
          <div class='d-flex justify-end mb-3'>
            <k-warning-btn
              icon='mdi-reload'
              text='刷新画面'
              @click='reloadImg'
            />
          </div>
          <v-img :lazy-src='pic' :src='pic'>
            <template #placeholder>
              <v-row align='center' class='fill-height ma-0' justify='center'>
                <v-progress-circular
                  color='grey lighten-5'
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </template>
      </k-crud-dialog>
      <v-toolbar flat>
        <div class='text-h5'>摄像头</div>
        <v-spacer></v-spacer>
        <k-primary-button
          v-if="hasPermission(['hardware.camera.add'])"
          icon='mdi-plus'
          text='添加'
          @click='
            crudActionMX().newItem(crud.default, () => {
              onOpenEdit(crud.default);
            })
          '
        />
        <k-crud-dialog
          v-model='crud.switch'
          :actionshow='!isDisable'
          :maxwidth='crud.widths'
          :title='crud.title'
          @close='
            () => {
              crudActionMX().closeEditingPage(crud);
            }
          '
          @save='
            () => {
              crudActionMX().saveItem();
            }
          '
        >
          <!-- //新增 -->
          <template #dialogContent>
            <v-form :ref='crud.form.ref' :disabled='isDisable'>
              <v-container>
                <v-row no-gutters>
                  <v-col class='col_width'>
                    <k-tree-select
                      v-model='cruddataMX.data.organizationUuid'
                      treeKey="uuid"
                      :disabled='isEdit'
                      :items='organizationList'
                      :rules='[globalRules.multiple]'
                      class='my-1'
                      label='机构名称'
                      placeholder='请选择机构名称'
                    />
                    <!-- <k-select
                      v-model="cruddataMX.data.organizationUuid"
                      :items="organizationList"
                      label="机构名称"
                      :loading="crud.select.roles.loading"
                      :disabled="crud.select.roles.loading"
                      class="my-2"
                    /> -->
                  </v-col>
                  <v-col class='col_width'>
                    <k-select
                      v-model='cruddataMX.data.areaUuid'
                      :disabled='crud.select.roles.loading'
                      :items='commonAreaList'
                      :loading='crud.select.roles.loading'
                      :rules='areaUuidRules'
                      class='my-2'
                      label='位置'
                      required
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class='col_width'>
                    <k-text-input
                      v-model='cruddataMX.data.cameraName'
                      :rules='cameraNameRules'
                      class='my-2'
                      label='名称'
                      required
                    />
                  </v-col>
                  <v-col class='col_width'>
                    <k-text-input
                      v-model='cruddataMX.data.manufacturer'
                      class='my-2'
                      label='厂商'
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class='col_width'></v-col>
                  <v-col class='col_width'></v-col>
                </v-row>
                <v-row v-if="cruddataMX.data.protocol !== 'rtsp'" no-gutters>
                  <v-col class='col_width'>
                    <k-text-input
                      v-model='cruddataMX.data.ipAddr'
                      :rules='ipAddrRules'
                      class='my-2'
                      label='IP'
                      required
                    />
                  </v-col>
                  <v-col class='col_width'>
                    <k-text-input
                      v-model='cruddataMX.data.port'
                      :rules='portRules'
                      class='my-2'
                      label='端口'
                      required
                    />
                  </v-col>
                </v-row>
                <v-row v-if="cruddataMX.data.protocol !== 'rtsp'" no-gutters>
                  <v-col class='col_width'
                  >
                    <k-text-input
                      v-model='cruddataMX.data.loginId'
                      :rules='iloginIdRules'
                      class='my-2'
                      label='用户'
                      required
                    />
                  </v-col>
                  <v-col class='col_width'>
                    <k-text-input
                      v-model='cruddataMX.data.password'
                      :rules='passwordRules'
                      class='my-2'
                      label='密码'
                      required
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class='col_width'>
                    <k-text-input
                      v-model='cruddataMX.data.channel'
                      class='my-2'
                      label='通道'
                    />
                  </v-col>
                  <v-col class='col_width'>
                    <k-select
                      v-model='cruddataMX.data.protocol'
                      :disabled='crud.select.roles.loading'
                      :items='protocolList'
                      :loading='crud.select.roles.loading'
                      :rules='showProtocolRules'
                      class='my-2'
                      label='协议'
                      required
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class='col_width'>
                    <div v-if="cruddataMX.data.protocol == 'rtsp'">
                      <k-text-input
                        v-model='cruddataMX.data.rtsp'
                        :rules='rtspRules'
                        class='my-2'
                        label='取流地址'
                        max-length='200'
                        required
                      />
                    </div>
                  </v-col>
                  <!-- <v-col class="col_width">

                  </v-col> -->
                </v-row>
              </v-container>
              <div class='d-flex flex-column'>
                <!-- <div>{{ showProtocol }}</div> -->
              </div>
            </v-form>
          </template>
        </k-crud-dialog>
      </v-toolbar>
    </template>
    <template #statusName>
      <!--      <div-->
      <!--        v-if="item.status === '01'"-->
      <!--        style="display: flex; align-items: center"-->
      <!--      >-->
      <!--        <span style="color: #1fc97a; font-size: 30px">·</span>-->
      <!--        <div>{{ item.statusName }}</div>-->
      <!--      </div>-->

      <div style='display: flex; align-items: center'>
        <span style='color: #fe9a07; font-size: 30px'>·</span>
        <div>未知</div>
      </div>
    </template>
    <template #actions='{ item }'>
      <k-table-primary-btn
        color='primary'
        text='查看'
        @click='crudActionMX().editItem(item, () => onOpenEdit(item, true))'
      ></k-table-primary-btn>
      <k-table-warning-btn
        v-if="hasPermission(['hardware.camera.edit'])"
        text='修改'
        @click='crudActionMX().editItem(item, () => onOpenEdit(item,false,false))'
      >
      </k-table-warning-btn>
      <k-table-error-btn
        v-if="hasPermission(['hardware.camera.delete'])"
        text='删除'
        @click='crudActionMX().deleteItem(item)'
      >
      </k-table-error-btn>
    </template>
  </k-crud-table>
</template>
<script>
import httpConfig from '@/config/http.config';

export default {
  props: {
    value: undefined,
    dosearchflg: { type: Boolean, default: true },
  },
  data() {
    return {
      isEdit: false,
      pic: '',
      rowItem: '',
      commonUrl: httpConfig.imgUrl,
      showProtocol: '',
      lookOff: false,
      isDisable: false, //是否是详情
      crud: {
        name: '摄像头',
        title: '', // 在Mixin中被修改
        switch: false,
        widths: '700',
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm1',
        },
        select: {
          roles: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
            dataset: [],
          },
        },
        default: {
          organizationUuid: [],
          areaUuid: '',
          cameraName: '',
          manufacturer: '',
          ipAddr: '',
          port: '',
          loginId: '',
          password: '',
          channel: '',
          protocol: '',
          rtsp: '',
        },
      },
      organization: [(v) => !!v || '位置不能为空'],
      areaUuidRules: [(v) => !!v || '位置不能为空'],
      cameraNameRules: [(v) => !!v || '名称不能为空'],
      ipAddrRules: [
        (v) => !!v || 'IP不能为空',
        (v) =>
          /((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))/.test(
            v,
          ) || 'IP地址不规范',
      ],
      portRules: [(v) => !!v || '端口不能为空'],
      iloginIdRules: [(v) => !!v || '用户不能为空'],
      passwordRules: [
        (v) => !!v || '密码不能为空',
        // (v) => /(^[a-zA-Z]\w{5,17}$)/.test(v) || '密码不规范',
      ],
      showProtocolRules: [(v) => !!v || '协议不能为空'],
      rtspRules: [(v) => !!v || '取流地址不能为空'],
      // organizationUuid: [],
      cameraColumns: [
        { text: '序号', value: 'indexNum', sortable: false, align: 'center' },
        {
          text: '机构名称',
          value: 'orgName',
          sortable: false,
          align: 'center',
        },
        { text: '位置', value: 'areaName', sortable: false, align: 'center' },
        { text: '名称', value: 'cameraName', sortable: false, align: 'center' },
        {
          text: '厂商',
          value: 'manufacturer',
          sortable: false,
          align: 'center',
        },
        { text: 'IP', value: 'ipAddr', sortable: false, align: 'center' },
        { text: '端口', value: 'port', sortable: false, align: 'center' },
        { text: '用户', value: 'loginId', sortable: false, align: 'center' },
        { text: '密码', value: 'password', sortable: false, align: 'center' },
        { text: '通道', value: 'channel', sortable: false, align: 'center' },
        { text: '协议', value: 'protocol', sortable: false, align: 'center' },
        { text: '取流地址', value: 'rtsp', sortable: false, align: 'center' },
        { text: '状态', value: 'statusName', sortable: false, align: 'center' },
        {
          text: '操作',
          value: 'actions',
          sortable: false,

          align: 'center',
        },
      ],
      rules: {
        required: (value) => !!value || '不能为空.',
      },
      protocolList: [],
      organizationList: [],
      commonAreaList: [],
      childrenList: [],
      rtspShowFlg: false,
    };
  },
  computed: {},
  watch: {
    value() {
      this.cruddataMX.conditions.searchParam = this.value;
    },
    dosearchflg() {
      this.crudConditionsMX().resetPage();
    },
  },
  created() {
    this.getProtocolList();
    this.getOrTree();
    this.getOrganizationTypeList();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.device.hardware.getDevHardwareCamera,
        add: (parmas) => {
          // parmas.protocol = this.showProtocol;
          // const treeid = this.cruddataMX.data.organizationUuid.toString();
          parmas.organizationUuid = parmas.organizationUuid.toString();
          return this.$api.device.hardware.addCamera(parmas);
        },
        edit: (parmas) => {
          parmas.organizationUuid = parmas.organizationUuid.toString();
          return this.$api.device.hardware.editCamera(parmas);
        },
        delete: this.$api.device.hardware.deleteCamera,
      },

      {},

      {
        add: [
          'organizationUuid',
          'areaUuid',
          'cameraName',
          'manufacturer',
          'ipAddr',
          'port',
          'loginId',
          'password',
          'channel',
          'protocol',
          'rtsp',
        ],
        edit: [
          'uuid',
          'organizationUuid',
          'areaUuid',
          'cameraName',
          'manufacturer',
          'ipAddr',
          'port',
          'loginId',
          'password',
          'channel',
          'protocol',
          'rtsp',
        ],
      },
    );
  },
  methods: {
    onOpenEdit(item, flag = false, isEdit = false) {
      // this.cruddataMX.data = { ...item };
      this.isEdit = isEdit;
      this.isDisable = flag;
      if (flag) {
        setTimeout(() => {
          this.crud.title = `查看${this.crud.name}`;
        }, 200);
      }
      if (item.orgCode) {
        // const data = {...item}
        this.$set(this.cruddataMX, 'data', { ...item });

      //  this.cruddataMX.data.organizationUuid = [item.orgCode];
        // this.showProtocol = item.protocol;
      }
      item.roles = [];
      item.roleUuids = [];
      item.roleVos?.forEach((p) => {
        item.roles.push(p.name);
        item.roleUuids.push(p.uuid);
      });
    },
    lookUp(item) {
      console.log(item);
      this.rowItem = { ...item };
      this.lookOff = true;
      this.isDisable = true;
      this.reloadImg();
    },
    reloadImg() {
      const item = this.rowItem;
      this.$api.networkConfig.networkConfig
        .getCameraImg({ cameraUuid: item.uuid })
        .then(({ data, code }) => {
          if (code === 200) {
            this.pic = data[0];
          }
        });
    },
    getProtocolList() {
      this.$api.device.hardware.getProtocolList().then((res) => {
        this.protocolList = res.data;
      });
    },
    getOrTree() {
      this.$api.organization.organization.getOrTree().then(({ code, data }) => {
        if (code === 200) {
          this.organizationList = data;
        }
      });
    },
    getOrganizationTypeList() {
      this.$api.device.hardware.getOrganizationTypeList().then((res) => {
        this.commonAreaList = res.data;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.col_width {
  width: 50%;
}
</style>
